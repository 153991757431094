import moment from 'moment'

const MORNING = 'morning'
const EVENING = 'evening'

export default {
  data: () => ({
    totalCount: null,
    totalDiscount: null
  }),
  computed: {
    isSameDay() {
      const arrivalTime = moment(this.time[0]).format('YYYY-MM-DD')
      const departureTime = moment(this.time[1]).format('YYYY-MM-DD')
      return arrivalTime === departureTime
    },
    isHalfDay() {
      if (this.isSameDay) {
        return true
      } else if (this.arrival_time === 'evening' && this.departure_time === 'morning' && this.daysCount > 1) {
        return true
      } else {
        return false
      }
    },
    isHalfDayNight() {
      if (
        this.arrival_time === "evening" &&
        this.departure_time === "morning" &&
        this.daysCount === 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    currentDays() {
      const arrivalTime = moment(this.time[0]).format('YYYY-MM-DD')
      const departureTime = moment(this.time[1]).format('YYYY-MM-DD')
      const days = [arrivalTime, departureTime]
      const chosenDays = []
      this.dateMonthsData.forEach((item) => {
        days.forEach((day) => {
          if (day === item.date) chosenDays.push(item)
        })
      })
      return chosenDays.sort((a, b) => a.id - b.id)
    },
    hasWeekend() { 
      let hasWeekend = false
      
      if (this.currentDays && this.currentDays.length) {
        let weekendDay = this.currentDays.find((item) => item.type === 'weekend')
        hasWeekend = weekendDay ? true : false
      }

      return hasWeekend
    },
    // Формирование периодов для вычесление тарифа
    // Перенесенный алгоритм с бэка
    dayPeriods() {
      if (this.rangedDates.length) {
        const arrivalTime = this.arrival_time
        const departureTime = this.departure_time
        const calendarDates = [...this.rangedDates]
        let periods = []
        let rawPeriods = this.getRawPeriods(arrivalTime, departureTime, calendarDates)
        let reservationLastPeriod = rawPeriods[rawPeriods.length - 1]

        for (let i = 0; i < rawPeriods.length; i++) {
          const rawPeriod = rawPeriods[i]
          if (this.periodNeedToDivide(rawPeriod.date, rawPeriod.dateAfter)) {
            const [endOfCurrentPeriod, newPeriodStart] = this.divideToSubPeriods(rawPeriod)
            periods.push(endOfCurrentPeriod)
            periods = periods.concat(this.makeReservationCalculationPeriod(newPeriodStart, reservationLastPeriod))
          } else {
            if (!periods.length) {
              periods.push(rawPeriod)
              continue
            }

            const lastDay = periods[periods.length - 1]
            if (reservationLastPeriod.dateAfter.date === lastDay.dateAfter.date &&
                reservationLastPeriod.dateAfter.time === lastDay.dateAfter.time) {
              break
            }
            
            periods.push(rawPeriod)
          }
        }

        return periods
      }
    },
    // Формирование массива тарифов для расчета сумм за сектор/рыбака/женщину
    dayPeriodSumms() {
      let paymentTariffs = []

      if (this.dayPeriods.length) {
        this.dayPeriods.forEach((period) => {
          const startPeriod = period.date
          const endPeriod = period.dateAfter

          let periodType = 'workday'

          if (startPeriod.type === endPeriod.type) {
            periodType = startPeriod.type
          }

          if ((startPeriod.type !== endPeriod.type) && startPeriod.time === 'evening') {
            periodType = 'weekend'
          }

          if ((startPeriod.type !== endPeriod.type) && startPeriod.time === 'morning') {
            periodType = 'weekend'
          }

          paymentTariffs.push({
            type: periodType === 'workday' ? 'regular' : periodType,
            hours: startPeriod.hours
          })
        })
      }

      return paymentTariffs
    },
    tacklesTotalCount() {
      return this.pricesData.tacklesPrice * this.formData.additional_tackles
    },
    daysCount() {
      return moment(this.currentDays[1].date).diff(moment(this.currentDays[0].date), 'days') + 1
    },
    hoursCount() {
      let firstTime = ''
      let lastTime = ''

      const rangedDatesArr = this.rangedDates.slice()
      const firstDay = moment(rangedDatesArr[0].date)
      const lastDay = moment(rangedDatesArr[rangedDatesArr.length - 1].date)

      if (this.arrival_time === this.departure_time) {
        if (this.arrival_time === 'morning') {
          firstTime = firstDay.add(7, 'hours')
          lastTime = lastDay.add(7, 'hours')
        } else {
          firstTime = firstDay.add(19, 'hours')
          lastTime = lastDay.add(19, 'hours')
        }
      }

      if (this.arrival_time !== this.departure_time) {
        if (this.arrival_time === 'morning') {
          firstTime = firstDay.add(7, 'hours')
          lastTime = lastDay.add(19, 'hours')
        } else {
          firstTime = firstDay.add(19, 'hours')
          lastTime = lastDay.add(7, 'hours')
        }
      }

      return lastTime.diff(firstTime, 'hours')
    },
    rangedDates() {
      const dateMonthArr = this.dateMonthsData.sort((a, b) => a.id - b.id).slice()
      const arrivalTimeIndex = dateMonthArr.indexOf(this.currentDays[0])
      const slicedDates = dateMonthArr.splice(arrivalTimeIndex, this.daysCount)
      return slicedDates
    },
    daysHaveWeekends() {
      let weekends = []

      if (this.rangedDates.length) {
        weekends = this.rangedDates.filter((item) => item.type === 'weekend')
      }

      return !!weekends.length
    },
    sectorsCount() {
      let count = null

      if (this.reservedSector && this.dayPeriodSumms.length) {
        this.dayPeriodSumms.forEach((item) => {
          count += this.reservedSector[`price_${item.type}_${item.hours}`]
        })
      }

      return count
    },
    goodsDiscount() {
      return null
    },
    goodsDiscountPrice() {
      return this.goodsDiscount ? this.goodsCount - (this.goodsCount * this.goodsDiscount) / 100 : this.goodsCount
    },
    womenTotalCount() {
      let womenCount = 0

      if (this.reservedSector && this.dayPeriodSumms.length) {
        this.dayPeriodSumms.forEach((item) => {
          const type = item.type[0].toUpperCase() + item.type.slice(1)
          womenCount += this.pricesData[`womenPrice${type}${item.hours}`]
        })
      }

      const womenNumber = this.formData.women_count
      const total = womenNumber * womenCount
      return total
    },
    fishermanTotalCount() {
      let fishermanCount = 0

      if (this.reservedSector && this.dayPeriodSumms.length) {
        this.dayPeriodSumms.forEach((item) => {
          const type = item.type[0].toUpperCase() + item.type.slice(1)
          fishermanCount += this.pricesData[`fishermanPrice${type}${item.hours}`]
        })
      }

      return fishermanCount
    },
    fishermansTotalCount() {
      const fishermanNumber = this.formData.fisherman_count

      return fishermanNumber * this.fishermanTotalCount
    },
    twoFishermanPrice() {
      return this.formData.fisherman_count * this.fishermanTotalCount
    },
    twoFishermanDiscountPrice() {
      const discount = this.fishermanDiscount
        ? this.twoFishermanPrice - (this.twoFishermanPrice * this.fishermanDiscount) / 100
        : this.twoFishermanPrice
      return discount
    },
    fishermansTotalDiscount() {
      return this.fishermansTotalCount - (this.twoFishermanPrice - this.twoFishermanDiscountPrice)
    },
    fishermanDiscountPrice() {
      const discount = this.fishermanDiscount
        ? this.fishermanTotalCount - (this.fishermanTotalCount * this.fishermanDiscount) / 100
        : this.fishermanTotalCount
      return discount
    },
    reservationDiscount() {
      return null
    },
    fishermanDiscount() {
      return null
    },
    sectorsDiscountPrice() {
      return this.reservationDiscount ? this.sectorsCount - (this.sectorsCount * this.reservationDiscount) / 100 : this.sectorsCount
    },
    goodsCount() {
      const total = this.productsData.reduce(function (total, currentValue) {
        return currentValue.selected_amount ? total + currentValue.price * currentValue.selected_amount : total + 0
      }, 0)
      return total
    },
    servicesCount() {
      const total = this.servicesData.reduce(function (total, currentValue) {
        return currentValue.selected_amount ? total + currentValue.price * currentValue.selected_amount : total + 0
      }, 0)
      return total
    },
    servicesDiscount() {
      return null
    },
    servicesDiscountPrice() {
      return this.servicesDiscount ? this.servicesCount - (this.servicesCount * this.servicesDiscount) / 100 : this.servicesCount
    },
    tacklesCount() {
      return this.formData.additional_tackles * this.pricesData.tacklesPrice
    },
    tacklesDiscount() {
      return null
    },
    tacklesDiscountPrice() {
      return this.tacklesDiscount ? this.tacklesCount - (this.tacklesCount * this.tacklesDiscount) / 100 : this.tacklesCount
    },
    // Сумма заказа
    globalTotalCount() {
      const totalCount =
        this.goodsCount + this.servicesCount + this.sectorsCount + this.womenTotalCount + this.fishermansTotalCount + this.tacklesCount
      this.totalCount = totalCount
      return totalCount
    },
    // Сумма скидки
    totalDiscountPrice() {
      const percent = this.globalTotalCount - this.globalTotalDiscount
      return Math.ceil(percent)
    },
    // Сумма заказа с учетом скидки
    globalTotalDiscount() {
      const totalDiscount =
        this.goodsDiscountPrice +
        this.servicesDiscountPrice +
        this.womenTotalCount +
        this.sectorsDiscountPrice +
        this.fishermansTotalDiscount +
        this.tacklesDiscountPrice
      this.totalDiscount = totalDiscount
      return Math.ceil(totalDiscount)
    }
  },
  methods: {
    createPeriod(date, time, type) {
      return { date, time, type }
    },
    createReservationDates(startPeriod, endPeriod) {
      const hours = startPeriod.time === endPeriod.time ? 24 : 12
      return {
        date: {
          date: startPeriod.date,
          time: startPeriod.time,
          type: startPeriod.type,
          hours
        },
        dateAfter: {
          date: endPeriod.date,
          time: endPeriod.time,
          type: endPeriod.type,
          hours
        }
      }
    },
    periodNeedToDivide(startDay, endDay) {
      let isNeed = false

      if (
        (startDay.time === endDay.time) &&
        (((startDay.type !== endDay.type) && (startDay.type === 'workday' && startDay.time === 'morning')) ||
        ((startDay.type !== endDay.type) && (startDay.type === 'weekend' && startDay.time === 'evening')))
      ) {
        isNeed = true
      }

      return isNeed
    },
    divideToSubPeriods(period) {
      const startDay = period.date
      const endDay = period.dateAfter
      
      if (startDay.time === MORNING) {
        return [
          this.createReservationDates(
            this.createPeriod(startDay.date, MORNING, startDay.type),
            this.createPeriod(startDay.date, EVENING, startDay.type)
          ),
          this.createReservationDates(
            this.createPeriod(startDay.date, EVENING, startDay.type),
            this.createPeriod(endDay.date, MORNING, endDay.type)
          )
        ]
      }

      return [
        this.createReservationDates(
          this.createPeriod(startDay.date, EVENING, startDay.type),
          this.createPeriod(endDay.date, MORNING, startDay.type)
        ),
        this.createReservationDates(
          this.createPeriod(endDay.date, MORNING, endDay.type),
          this.createPeriod(endDay.date, EVENING, endDay.type)
        )
      ]
    },
    strDateToNum(stringDate) {
      return parseInt(stringDate.replace(/[-]/g, ''))
    },
    makeReservationCalculationPeriod(start, end) {
      const arrivalTime = start.date.time;
      const departureTime = end.dateAfter.time;

      let calendarDates = [...this.rangedDates]
      
      calendarDates = calendarDates.filter(item => {
        return this.strDateToNum(item.date) >= this.strDateToNum(start.date.date) && 
        this.strDateToNum(item.date) <= this.strDateToNum(start.dateAfter.date)
      })

      let rawPeriods = this.getRawPeriods(arrivalTime, departureTime, calendarDates)
      let periods = []
      const lastPeriod = rawPeriods[rawPeriods.length - 1]
      
      rawPeriods.forEach((rawPeriod) => {
        if (this.periodNeedToDivide(rawPeriod.date, rawPeriod.dateAfter)) {
          const [endOfCurrentPeriod, newPeriodStart] = this.divideToSubPeriods(rawPeriod)
          periods.push(endOfCurrentPeriod)
          periods = periods.concat(this.makeReservationCalculationPeriod(newPeriodStart, lastPeriod))
        } else {
          periods.push(rawPeriod)
        }
      })

      return periods
    },
    getRawPeriods(arrivalTime, departureTime, calendarDates) {
      const calendarDaysCount = calendarDates.length
      let rawPeriods = []

      if (calendarDaysCount === 1 && arrivalTime !== departureTime) {
        const date = calendarDates[0].date

        rawPeriods.push(this.createReservationDates(
          this.createPeriod(date, arrivalTime, calendarDates[0].type),
          this.createPeriod(date, departureTime, calendarDates[0].type)
        ))
      }

      if (calendarDaysCount === 2) {
        const date = calendarDates[0].date
        const dateAfter = calendarDates[1].date

        if (arrivalTime === departureTime) {
          rawPeriods.push(this.createReservationDates(
            this.createPeriod(date, arrivalTime, calendarDates[0].type),
            this.createPeriod(dateAfter, departureTime, calendarDates[1].type)
          ))
        } else {
          if (arrivalTime === MORNING) {
            rawPeriods.push(this.createReservationDates(
              this.createPeriod(date, MORNING, calendarDates[0].type),
              this.createPeriod(dateAfter, MORNING, calendarDates[1].type)
            ))

            rawPeriods.push(this.createReservationDates(
              this.createPeriod(dateAfter, MORNING, calendarDates[1].type),
              this.createPeriod(dateAfter, EVENING, calendarDates[1].type)
            ))
          }

          if (arrivalTime === EVENING) {
            rawPeriods.push(this.createReservationDates(
              this.createPeriod(date, arrivalTime, calendarDates[0].type),
              this.createPeriod(dateAfter, departureTime, calendarDates[1].type)
            ))
          }
        }
      }

      if (calendarDaysCount > 2) {
        let i = 0

        for (i; i < calendarDaysCount - 2; i++) {
          const date = calendarDates[i].date
          const dateAfter = calendarDates[i + 1].date

          rawPeriods.push(this.createReservationDates(
            this.createPeriod(date, arrivalTime, calendarDates[i].type),
            this.createPeriod(dateAfter, arrivalTime, calendarDates[i + 1].type)
          ))
        }

        const date = calendarDates[i].date
        const dateAfter = calendarDates[i + 1].date

        if (arrivalTime === departureTime) {
          rawPeriods.push(this.createReservationDates(
            this.createPeriod(date, arrivalTime, calendarDates[i].type),
            this.createPeriod(dateAfter, departureTime, calendarDates[i + 1].type)
          ))
        } else {
          if (arrivalTime === EVENING) {
            rawPeriods.push(this.createReservationDates(
              this.createPeriod(date, arrivalTime, calendarDates[i].type),
              this.createPeriod(dateAfter, departureTime, calendarDates[i + 1].type)
            ))
          }

          if (arrivalTime === MORNING) {
            rawPeriods.push(this.createReservationDates(
              this.createPeriod(date, MORNING, calendarDates[i].type),
              this.createPeriod(dateAfter, MORNING, calendarDates[i + 1].type)
            ))

            rawPeriods.push(this.createReservationDates(
              this.createPeriod(dateAfter, MORNING, calendarDates[i + 1].type),
              this.createPeriod(dateAfter, EVENING, calendarDates[i + 1].type)
            ))
          }
        }
      }

      return rawPeriods
    }
  }
}
