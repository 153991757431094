import { http } from '../http/axios'

const lakeNumber = 1

export function productsRequest() {
  const productsReq = http
    .get('landing/products', {
      params: {
        lake: lakeNumber
      }
    })
    .then((res) => {
      const productsRes = res.data.data
      return productsRes
    })
    .catch((e) => {
      throw new Error(`Ошибка получения услуг: ${e.message}`)
    })
  return productsReq
}

export function getPrices() {
  const pricesReq = http.get('prices?lake=1').then(res =>{
    const pricesRes = res.data.data
    return pricesRes
  }).catch((e) => {
    throw new Error(`Ошибка получения цен: ${e.message}`)
  })
  return pricesReq
}

export function servicesRequest() {
  const servicesReq = http
    .get('landing/services', {
      params: {
        lake: lakeNumber
      }
    })
    .then((res) => {
      const servicesRes = res.data.data
      return servicesRes
    })
    .catch((e) => {
      throw new Error(`Ошибка получения услуг: ${e.message}`)
    })
  return servicesReq
}

export function viewsRequest() {
  const viewsReq = http
    .get('landing/views', {
      params: {
        lake: lakeNumber
      }
    })
    .then((res) => {
      const viewsRes = res.data.data
      return viewsRes
    })
    .catch((e) => {
      throw new Error(`Ошибка получения видов: ${e.message}`)
    })
  return viewsReq
}

export function sectorsRequest(viewId = null) {
  const sectorsReq = http
    .get('landing/sectors', {
      params: {
        lake: lakeNumber,
        view: viewId,
        active: 1
      }
    })
    .then((res) => {
      const sectorsRes = res.data.data
      return sectorsRes
    })
    .catch((e) => {
      throw new Error(`Ошибка получения товаров: ${e.message}`)
    })
  return sectorsReq
}

export function sendClientPhoneNumber(phone) {
  const phoneReq = http
    .get('/reservations/get-verification-code?lake=1', {
      params: {
        phone
      }
    })
    .then((res) => {
      const phoneRes = res.data
      return phoneRes
    })
    .catch((e) => {
      throw new Error(`Ошибка получения номера телефона: ${e.message}`)
    })
  return phoneReq
}

/**
 *
 * @param {String} phone phone number
 * @param {String} code
 */
export function sendClientPhoneAndVerificationCode({ phone, code }) {
  const clientReq = http
    .get('/reservations/send-verification-code?lake=1', {
      params: {
        phone,
        code
      }
    })
    .then((res) => {
      const clientRes = res.data
      return clientRes
    })
    .catch(err => {
      console.log('err', err.message)
    })
  return clientReq
}

export function createOrder(data) {
  const orderReq = http.post('/landing/reservations', data).then((res) => {
    const orderRes = res.data
    return orderRes
  })
  return orderReq
}
/**
 *
 * @param {String} date YYYY-MM
 */
export function calendarRequest([date, additional_date]) {
  const datesReq = http
    .get('calendar', {
      params: {
        date,
        additional_date,
        lake: 1
      }
    })
    .then((res) => {
      const calendarRes = res.data
      return calendarRes
    })

  return datesReq
}

/**
 *
 * @param {String} date YYYY-MM
 */
export function getAvailableSectors({ arrival_date, departure_date, arrival_time, departure_time }) {
  const sectorsReq = http
    .get('landing/available_sectors?lake=1', {
      params: {
        arrival_date,
        departure_date,
        arrival_time,
        departure_time
      }
    })
    .then((res) => {
      const sectorsRes = res.data
      return sectorsRes
    })

  return sectorsReq
}

export function sendEmptySector(data) {
  const sectorsReq = http
    .post('landing/save-filter', data)
    .then((res) => {
      const sectorsRes = res.data
      return sectorsRes
    })

  return sectorsReq
}

export function getQuestionsData() {
  const questionsReq = http
    .get('questions?lake=1')
    .then((res) => {
      const questionsRes = res.data
      return questionsRes
    })

  return questionsReq
}
export function getGalleryData() {
  const galleryReq = http
    .get('slides?lake=1')
    .then((res) => {
      const galleryRes = res.data
      return galleryRes
    })

  return galleryReq
}
export function getFishData() {
  const fishReq = http
    .get('fish_slides?lake=1')
    .then((res) => {
      const fishRes = res.data
      return fishRes
    })

  return fishReq
}
export function getGoodsAndServices() {
  const productsReq = http
    .get('nomenclature_slides?lake=1')
    .then((res) => {
      const productsRes = res.data
      return productsRes
    })

  return productsReq
}

export function getMapSectorsStatus(data) { 
  const sectorsStatusReq = http
    .get('sectors/map-sectors?lake=1', data)
    .then(res => {
      const sectorsStatusRes = res.data
      return sectorsStatusRes
    })
  return sectorsStatusReq
}

export function getLakeActivity() {
  const lakeActivityReq = http
    .get('/landing/check-lake-activity/1')
    .then(res => {
      const lakeActivityRes = res.data
      return lakeActivityRes
    })
    return lakeActivityReq
}
