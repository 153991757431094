<template>
  <div class="modal modal--error modal--layout" :class="{active: modalIsOpen}" tabindex="-1" role="dialog" id="modal--error">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <p class="txt--bold">Произошла техническая ошибка!<br />В скором времени мы все поправим. </p>
          <p class="txt--smaller color-mint-deep-2 m-0">Для бронирования сектора позвоните по телефону: <a href="tel:+77025558500">+7 702 555 85 00</a></p>
        </div>

        <div class="modal-close hover--svg" data-dismiss="modal" @click="closeModal">
          <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="-1.5" x2="41.3096" y2="-1.5" transform="matrix(-0.724059 -0.689738 0.723174 -0.690666 33.1113 29.3105)" stroke="white" stroke-width="3"/>
            <line y1="-1.5" x2="41.2853" y2="-1.5" transform="matrix(-0.722865 0.690989 -0.721789 -0.692113 30.291 0.817871)" stroke="white" stroke-width="3"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'modal',
        props: {
            modalIsOpen: {
                type: Boolean,
                default: false,
            }
        },
        methods: {
            closeModal() {
                this.$emit('closeModal')
            }
        },
    }
</script>
<style>
  .modal.active {
    display: block;
    background-color: rgba(0,0,0,.5);
    overflow: auto;
  }
  .modal-content {
    margin: 50px 0;
    position: relative;
    min-height: 322px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-close {
    position: absolute;
    right: -50px;
    top: -50px;
  }
</style>