import { productsRequest, servicesRequest, getAvailableSectors, viewsRequest, calendarRequest, getPrices } from '../api-requests/api'
import moment from 'moment'

export default {
  data: () => ({
    viewsData: [],
    sectorsData: [],
    productsData: [],
    servicesData: [],
    calendarData: [],
    dateMonthsData: [],
    pricesData: {
      fishermanPriceRegular12: null,
      fishermanPriceRegular24: null,
      womenPriceRegular12: null,
      womenPriceRegular24: null,
      fishermanPriceWeekend12: null,
      fishermanPriceWeekend24: null,
      womenPriceWeekend12: null,
      womenPriceWeekend24: null,
      tacklesPrice: null
    }
  }),
  methods: {
    getViews() {
      viewsRequest().then((res) => {
        this.viewsData = res
        this.currentViewFilter = 'Все'
      }).catch(err => {
        throw new Error(`Ошибка установки начальных значений видов: ${err.message}`)
      })
    },
    getSectors() {
      this.loading = true
      const sectorsData = {
        arrival_date: moment(this.time[0]).format('YYYY-MM-DD'),
        departure_date: moment(this.time[1]).format('YYYY-MM-DD'),
        arrival_time: this.arrival_time,
        departure_time: this.departure_time
      }
      getAvailableSectors(sectorsData).then((res) => {
        this.sectorsData = res.data
        this.availableSectors = res.data
        this.displaySectors = res.data.slice(0, 3)
        this.loading = false
      }).catch(err => {
        this.loading = false
        throw new Error(`Ошибка установки начальных значений секторов: ${err.message}`)
      })
    },
    getProducts() {
      productsRequest().then((res) => {
        const resData = res.map((item) => {
          return Object.assign(item, { selected_amount: 0 })
        })
        this.productsData = resData
        this.displayGoods = resData.filter(item => item.category === 'fishing')
      }).catch(err => {
        throw new Error(`Ошибка установки начальных значений товаров: ${err.message}`)
      })
    },
    getServices() {
      servicesRequest().then((res) => {
        const resData = res.map((item) => {
          return Object.assign(item, { selected_amount: 0 })
        })
        this.servicesData = resData
        this.displayServices = resData.filter(item => item.type === 'fishing')
      }).catch(err => {
        throw new Error(`Ошибка установки начальных значений услуг: ${err.message}`)
      })
    },
    getCalendarDates() {
      calendarRequest().then(res => {
        this.calendarData = res
      }).catch(err => {
        throw new Error(`Ошибка установки начальных значений дат: ${err.message}`)
      })
    },
    setDateMonths(datesArr) {
      this.dateMonthsData = datesArr
    },
    getPricesData() {
      getPrices().then(data => {
       
        this.pricesData.fishermanPriceRegular12 = data[0].value
        this.pricesData.fishermanPriceRegular24 = data[1].value
        this.pricesData.womenPriceRegular12 = data[2].value
        this.pricesData.womenPriceRegular24 = data[3].value
        this.pricesData.fishermanPriceWeekend12 = data[4].value
        this.pricesData.fishermanPriceWeekend24 = data[5].value
        this.pricesData.womenPriceWeekend12 = data[6].value
        this.pricesData.womenPriceWeekend24 = data[7].value
        this.pricesData.tacklesPrice = data[8].value
      }).catch(err => {
        throw new Error(`Ошибка установки начальных значений цен: ${err.message}`)
      })
    }
  },
  created() {
    this.getViews()
    this.getSectors()
    this.getProducts()
    this.getServices()
    this.getPricesData()


    // this.setDateMonths()
    // this.$bus.$on('syncDataMonths', this.setDateMonths)
  }
}
